export default [
  {
    "image": "https://images.g2a.com/1024x576/1x1x1/random-legendary-10-keys-steam-key-global-i10000256947003/bac9fb36a2194d90bbd04cfb",
    "name": "Random Steam Legendary keys",
    "description": "The following product contains various Steam CD-Keys of different values, selected at random",
    "options": [
      { "price": 1, "name": "3 keys" },
      { "price": 2.2, "name": "5 keys" },
      { "price": 5, "name": "10 keys" },
      { "price": 10, "name": "25 keys" },
      { "price": 15, "name": "50! keys" },
    ]
  },
  {
    "image": "https://images.g2a.com/1024x576/1x1x1/xbox-game-pass-3-months-trial-for-pc-microsoft-key-global-i10000080969042/5cc03659ae653ac9c43f9f65",
    "name": "Xbox Game Pass - Core | GLOBAL",
    "description": "Xbox Game Pass for PC Play over 100 high-quality PC games on Windows 10, plus enjoy all the benefits of EA Play, coming this holiday at no extra cost",
    "options": [
      { "price": 4, "name": "1 month" },
      { "price": 15, "name": "3 months" },
      { "price": 20, "name": "6 months" },
      { "price": 40, "name": "12 months" },
    ]
  },
  {
    "image": "https://images.g2a.com/1024x576/1x1x1/xbox-game-pass-ultimate-1-month-xbox-live-key-global-i10000188766085/60644bd746177c42071452d2",
    "name": "Xbox Game Pass Ultimate",
    "description": "Xbox Game Pass Ultimate | Xbox Live Key - GLOBAL",
    "options": [
      { "price": 5, "name": "1 month" },
      { "price": 17, "name": "3 months" },
      { "price": 35, "name": "6 months" },
      { "price": 100, "name": "12 months" },
    ]
  },
  {
    "image": "https://images.g2a.com/1024x576/1x1x1/playstation-network-gift-card-100-usd-psn-united-states-i10000070169008/59e7362cae653aa6f12c6654",
    "name": "PlayStation Network Gift Card",
    "description": "PlayStation Network Gift Card is used to add funds to any Sony Entertainment Network account wallet. Accumulated funds can be spent on a variety of goods in the PlayStation Store",
    "options": [
      { "price": 6, "name": "10$" },
      { "price": 13, "name": "25$" },
      { "price": 20, "name": "40$" },
      { "price": 30, "name": "50$" },
      { "price": 33, "name": "55$" },
      { "price": 40, "name": "75$" },
      { "price": 70, "name": "100$" },
      { "price": 75, "name": "110$" },
    ]
  },
]
