export function Refund() {
  return (
    <div className="mx-auto text-sm" style={{ maxWidth: "960px" }}>
      <div className="mb-10">
        <h2 className="text-2xl mb-5 font-semibold">Digital Gift Card Delivery (Email Code Delivery)</h2>
        <p>
          All gift cards are delivered electronically via email delivery. After
          your order is processed, we begin the verification steps immediately.
          If no further verification is required, then your gift card will be
          emailed out immediately. We accept customers from all over the world.{" "}
        </p>

        <p>
          If you do not see any emails in your inbox, please check your spam
          folder.{" "}
        </p>

        <p>
          If you believe there has been an error in your delivery, please
          contact customer support. Giffter Satisfaction Guaranteed
        </p>
      </div>
      <div className="mb-10">
      <h2 className="text-2xl mb-5 font-semibold">What is your Refund Policy?</h2>
        <p>
          We have a 100% guarantee that the card you purchase will be exactly as
          described on our website and in 100% working order. Due to the nature
          of a gift card business, after your link has been sent and opened, we
          are no longer able to refund your purchase. If your gift card has been
          delivered but your delivery link has not been opened or viewed by
          anyone, we will then be able to refund your order.{" "}
        </p>

        <p>
          If there is any concern you have about an order and you want us to
          take a special look, please reach out to customer support and we will
          assist you.
        </p>
      </div>
      <div className="mb-10">
      <h2 className="text-2xl mb-5 font-semibold">Compliance with OFAC</h2>
        <p>
          Giffter is sanctioned and prohibited from transacting with
          both individuals and companies that are listed on the US Office of
          Foreign Assets Control sanctions list. Due to such regulations,
          Giffter must actively screen all customers against any
          government regulated sanctions lists in corresponding jurisdictions in
          which we operate.
        </p>
      </div>
      <div className="mb-10">
      <h2 className="text-2xl mb-5 font-semibold">“My gift card isn’t working. What do I do?”</h2>
        <p>
          If you have any issues with your card, please contact customer support
          and we will help troubleshoot your problem. If there is a problem with
          the code, we will get you a replacement card right away.
        </p>
      </div>
      <div className="mb-10">
      <h2 className="text-2xl mb-5 font-semibold">Is there an expiration date on the gift cards?</h2>
        <p>
          We offer a 100% guarantee that all cards delivered by Giffter
          will be working and redeemable. Further, none of the cards we sell
          have any expiration date, so there is no rush or need to redeem your
          card right away.
        </p>
      </div>
    </div>
  );
}
