import { createContext, FC, useContext, useState } from "react";
import { useCard } from "../context/card.context";
import { IProduct, IRCContainer } from "../interfaces";
import { Header } from "../containers/header";
import { Footer } from "../containers/footer";
import Dialog from "../components/dialog";
import { CardView } from "../containers/card-view";
import { Checkout } from "../containers/checkout";
import { ProductDrilldown } from "../components/product-drilldown";
import { Outlet } from "react-router-dom";

export interface ILayoutContext {
  toggleCheckout(state: boolean): void
  toggleCart(state: boolean): void;
  toggleProduct(product: IProduct): void;
}

const LayoutContext = createContext<ILayoutContext | null>(null);

export const useLayout = () => useContext(LayoutContext)!;

export const Layout = () => {
  const shopping = useCard();
  const [cart, setCart] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const [product, setProduct] = useState<IProduct | null>(null);

  const onCardClose = () => setCart(false);
  const onCheckoutClose = () => setCheckout(false);
  const onStartCheckout = () => {
    setCart(false);
    setCheckout(true);
  };

  const onCancelPayment = () => {
    setCart(true);
    setCheckout(false);
  };

  return (
    <LayoutContext.Provider
      value={{
        toggleProduct: setProduct,
        toggleCart: setCart,
        toggleCheckout: setCheckout,
      }}
    >
      <>
        <Header onOpenCard={() => setCart(true)} />
        <div className="flex-grow">
        <Outlet />
        </div>
        <Footer onOpenProduct={setProduct} />

        <Dialog
          title="Shopping cart"
          onClose={onCardClose}
          visible={cart}
          footer={
            <CardView.ModalFooter
              onCheckout={onStartCheckout}
              onClose={onCardClose}
            />
          }
        >
          {<CardView onClose={onCardClose} />}
        </Dialog>

        <Dialog small clean onClose={onCheckoutClose} visible={checkout}>
          {<Checkout onClose={onCancelPayment} />}
        </Dialog>

        <Dialog visible={!!product} onClose={() => setProduct(null)}>
          {!!product && (
            <ProductDrilldown
              onAddToCard={shopping.add}
              onClose={() => setProduct(null)}
              product={product}
            />
          )}
        </Dialog>
      </>
    </LayoutContext.Provider>
  );
};
