export default [
    {
        "image": "https://images.g2a.com/1024x576/1x1x1/apple-itunes-gift-card-10-usd-itunes-united-states-i10000156778038/5c2ccaa55bafe3c237734453",
        "name": "iTunes gift card",
        "description": "Apple iTunes Gift Card | Any country",
        "options": [
            { "price": 6, "name": "10$" },
            { "price": 12, "name": "25$" },
            { "price": 35, "name": "50$" },
            { "price": 70, "name": "100$" },
            { "price": 90, "name": "150$" },
            { "price": 120, "name": "200$" },
            { "price": 200, "name": "300$" },
            { "price": 210, "name": "350$" },
            { "price": 250, "name": "400$" },
            { "price": 300, "name": "450$" },
            { "price": 370, "name": "500$" },
            { "price": 650, "name": "1000$" },
        ] 
    },
    {
        "image": "https://images.g2a.com/1024x576/1x1x1/google-play-gift-card-25-usd-united-states-i10000067145025/9ad4033ba3e84c1a8870bffd",
        "name": "Google Play Gift Card",
        "description": "The astronomic amount of different apps, games, books, movies and more on Google Play ensures that everybody will find something for themselves.",
        "options": [
            { "price": 6, "name": "10$" },
            { "price": 12, "name": "25$" },
            { "price": 35, "name": "50$" },
            { "price": 70, "name": "100$" },
            { "price": 90, "name": "150$" },
            { "price": 120, "name": "200$" },
        ] 
    },
    {
        "image": "https://images.g2a.com/1024x576/1x1x1/tinder-plus-1-month-tinder-key-global-i10000196270003/a9c450683ed44d51bdb66420",
        "name": "Tinder Plus | GLOBAL",
        "description": "Tinder Plus Subscription unlocks several advanced features to help you find new relationships",
        "options": [
            { "price": 4, "name": "1 month" },
            { "price": 8, "name": "6 month" },
            { "price": 20, "name": "1 year" }
        ] 
    },
    {
        "image": "https://images.g2a.com/1024x576/1x1x1/tinder-gold-1-month-tinder-key-global-i10000196271003/091efa8cffbe499ba2727c85",
        "name": "Tinder Gold",
        "description": "Premium subscription to the most popular online dating app with over 340 million users worldwide",
        "options": [
            { "price": 6, "name": "1 month" },
            { "price": 13, "name": "3 month" },
            { "price": 20, "name": "6 month" },
            { "price": 22, "name": "1 year" },
        ] 
    },
    {
        "image": "https://images.g2a.com/1024x576/1x1x1/twitch-gift-card-15-eur-twitch-key-europe-i10000206456026/5f16ea287e696c7ba01ff422",
        "name": "Twitch Gift Card",
        "description": "Twitch builds communities around live content that's interactive, diverse, and always next level.",
        "options": [
            { "price": 6, "name": "10$" },
            { "price": 12, "name": "25$" },
            { "price": 35, "name": "50$" },
            { "price": 70, "name": "100$" },
            { "price": 90, "name": "150$" },
            { "price": 120, "name": "200$" },
        ] 
    },
]