import cx from "classnames";
import * as uuid from "uuid";
import { sample } from "lodash";
import QRCode from "react-qr-code";
import { BounceLoader } from "react-spinners";
import DocumentDuplicateIcon from "@heroicons/react/24/outline/DocumentDuplicateIcon";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

import logo from "./assets/nowpayments.svg";

import { Input } from "../../components/input";
import { IRCContainer } from "../../interfaces";
import { config, formatAmount } from "../../utils";
import { useCard } from "../../context/card.context";
import { amountInCrypto } from "../../utils/amount-in-crypto";
import { IPaymentMethodConfig, methods } from "../../data/payment-methods";
import { useExchangeRatesQuery } from "../../queries/exchange-rates.query";

const MethodContext = createContext<IPaymentMethodConfig | null>(null);
const usePaymentMethod = () => useContext(MethodContext)!;

export function Checkout({ onClose }: { onClose?(): void }) {
  const card = useCard();
  const orderId = useMemo(() => uuid.v4(), []);
  const [email, setEmail] = useState<string | null>(null);
  const [method, setMethod] = useState<IPaymentMethodConfig | null>(null);

  const exchange = useExchangeRatesQuery(method?.asset!, {
    enabled: !!method,
  } as any);

  const wallet = useMemo(() => sample(method?.wallets), [method]);
  const onPaymentProcessed = () => {
    card.reset();
    return onClose?.();
  };

  let view = null;
  if (!method || exchange.isLoading) {
    view = (
      <Checkout.Start
        email={email!}
        onSetEmail={setEmail}
        loading={exchange.isLoading}
        onCancel={onClose}
        onSetMethod={setMethod}
      />
    );
  } else {
    view = (
      <Checkout.Payment
        email={email!}
        wallet={wallet!}
        onPaymentProcessed={onPaymentProcessed}
        method={method!}
        amount={card.toPay}
        onBack={() => setMethod(null)}
        rate={exchange.data!}
      />
    );
  }

  return <Checkout.Wrapper orderId={orderId}>{view}</Checkout.Wrapper>;
}

Checkout.CopyButton = function CopyButton({ text }: { text: string }) {
  if (!navigator || !navigator.clipboard || !navigator.clipboard.writeText) {
    return null;
  }

  const onCopy = () => {
    navigator.clipboard.writeText(text);

    return alert("Copied");
  };

  return (
    <DocumentDuplicateIcon
      onClick={onCopy}
      className="h-5 cursor-pointer opacity-60 hover:opacity-100"
    />
  );
};

Checkout.Payment = function CheckoutPayment({
  method,
  wallet,
  amount,
  rate,
  email,
  onBack,
  onPaymentProcessed,
}: {
  email: string;
  onBack(): void;
  method: IPaymentMethodConfig;
  wallet: string;
  amount: number;
  rate: number;
  onPaymentProcessed(): void;
}) {
  const [tracking, setTracking] = useState(false);

  const onTrackPayment = () => setTracking(true);

  useEffect(() => {
    if (tracking) {
      // 5m timeout = 1000 * 60 * 5;
      const timerId = setTimeout(() => {
        alert(
          `Payment processed. Gift card and receipt will be sent to ${email}`
        );
        return onPaymentProcessed();
      }, 1000 * 60 * 5);

      return () => clearInterval(timerId);
    }

    return;
  }, [tracking]);

  return (
    <div className="px-3 mb-2 pt-3">
      {tracking && (
        <div className="absolute t-0 l-0 w-full h-full bg-white opacity-75 flex flex-col items-center justify-center z-50">
          <BounceLoader color="#2563eb" />
          <p className="mt-5 text-sm px-10 text-center">
            Payment may take 5+ minutes. Please do not close this window while
            payment is processing...
          </p>
        </div>
      )}
      <div className="mb-5">
        <div className="text-center font-light">
          <img src={method.logo} className="h-12 mx-auto mb-3" />
          <p className="text-sm mb-2">{method.title}</p>
          {/* <p className="text-xs text-gray-500">
            {method.description ||
              "asdasdflkjasdfgfghasdflkjjhaskdljffhSLKDJFhj"}
          </p> */}
        </div>
        <div className="flex flex-col">
          <QRCode
            value={wallet!}
            className="rounded-xl shadow-lg border mx-auto mt-3 mb-5"
            imageRendering={require("../header/assets/logo.png")}
          />
          <div>
            <Input
              disabled
              addonAfter={<Checkout.CopyButton text={wallet} />}
              name="wallet"
              label="Wallet address"
              value={wallet}
              className="mb-2"
            />
            <Input
              disabled
              addonAfter={
                <Checkout.CopyButton
                  text={amountInCrypto(amount, rate).toString()}
                />
              }
              name="amount"
              label="Amount"
              value={amountInCrypto(amount, rate).toString()}
            />
          </div>
          <div className="text-xs mt-5 text-center text-gray-400">
            <p>
              NOWPayments is solely designed to give merchants access to payment
              processing features. Please direct any inquiries regarding goods
              or services to the respective store.
            </p>
            <div className="mx-auto mt-3 flex flex-row justify-center">
              <a rel="nofollow" href="//nowpayments.io">
                <img src={logo} className="h-5 mx-auto" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-between">
        <Checkout.SecondaryButton onClick={onBack} type="button">
          Back
        </Checkout.SecondaryButton>
        <Checkout.PrimaryButon onClick={onTrackPayment} type="button">
          Track payment
        </Checkout.PrimaryButon>
      </div>
    </div>
  );
};

Checkout.Start = function StartCheckout({
  onSetMethod,
  onSetEmail,
  email,
  onCancel,
  loading,
}: {
  email?: string;
  loading?: boolean;
  onCancel?(): void;
  onSetEmail?(email: string): void;
  onSetMethod(method: IPaymentMethodConfig): void;
}) {
  const [method, setMethod] = useState<IPaymentMethodConfig | null>(null);

  const onFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const form = new FormData(e.target as any);

    const email = form.get("email") as string;
    onSetEmail?.(email);
    return !!method && onSetMethod(method!);
  };

  return (
    <MethodContext.Provider value={method}>
      <form className="px-3 mb-2 pt-3 relative" onSubmit={onFormSubmit}>
        {loading && (
          <div className="absolute t-0 l-0 w-full h-full bg-white opacity-75 flex flex-col items-center justify-center z-50">
            <BounceLoader color="#2563eb" />
            <p className="mt-5 text-sm px-10 text-center">
              Preparing payment and generating address...
            </p>
          </div>
        )}
        <p className="mb-2 text-sm font-light">Select payment method</p>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 mb-3">
          <Checkout.MethodButton
            method={methods.usdt_bep20}
            onClick={setMethod}
          />
          <Checkout.MethodButton
            method={methods.usdt_matic}
            onClick={setMethod}
          />
          <Checkout.MethodButton
            method={methods.usdt_trc20}
            onClick={setMethod}
          />
          <Checkout.MethodButton method={methods.doge} onClick={setMethod} />
          <Checkout.MethodButton method={methods.btc} onClick={setMethod} />
          <Checkout.MethodButton method={methods.ltc} onClick={setMethod} />
          <Checkout.MethodButton method={methods.matic} onClick={setMethod} />
          <Checkout.MethodButton method={methods.monero} onClick={setMethod} />
          <Checkout.MethodButton method={methods.near} onClick={setMethod} />
          <Checkout.MethodButton method={methods.trx} onClick={setMethod} />
        </div>
        <div className="mb-5">
          <Input
            className="font-light"
            required
            label="Leave your email to deliver digital goods"
            initialValue={email}
            name="email"
            autoComplete="email"
          />
          <div className="mx-auto mt-5 flex flex-row justify-center">
              <a href="//nowpayments.io" rel="nofollow">
                <img src={logo} className="h-5 mx-auto" />
              </a>
            </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          <Checkout.SecondaryButton onClick={onCancel} type="button">
            Cancel
          </Checkout.SecondaryButton>
          <Checkout.PrimaryButon type="submit" disabled={!method}>
            Next
          </Checkout.PrimaryButon>
        </div>
      </form>
    </MethodContext.Provider>
  );
};

Checkout.MethodButton = function MethodCard({
  method,
  onClick,
}: {
  method: IPaymentMethodConfig;
  onClick(method: IPaymentMethodConfig): void;
}) {
  const ctx = usePaymentMethod();
  return (
    <div
      className={cx(
        "cursor-pointer text-center px-5 py-7 border rounded shadow-sm bg-slate-50 hover:opacity-100",
        {
          "opacity-65": method?.title !== ctx?.title,
          "border-slate-400 opacity-100": method?.title === ctx?.title,
        }
      )}
      onClick={() => onClick(method)}
    >
      <img src={method.logo} className="mb-5 h-10 mx-auto" />
      <p className="text-sm font-light">{method.title}</p>
    </div>
  );
};

Checkout.Wrapper = function CheckoutWrapper({
  children,
  orderId,
}: IRCContainer & { orderId: string }) {
  const cart = useCard();

  return (
    <div>
      <div className="CheckoutHeader border-b py-3 px-3">
        <p className="text-xs">
          <strong>Order id: #{orderId}</strong>
        </p>
      </div>
      <div className="CheckoutBody">{children}</div>
      <div className="ChecoutFooter border-t">
        <p className="text-center text-xs py-3 px-3">
          Payment to{" "}
          <a
            rel="nofollow"
            className="text-blue-500 hover:underline"
            href={`//${window.location.host}`}
          >
            {window.location.host}
          </a>{" "}
          | Amount: <strong>{formatAmount(cart.toPay)}</strong>
        </p>
      </div>
    </div>
  );
};

interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}
Checkout.Button = ({
  children,
  disabled,
  className,
  ...props
}: IButtonProps) => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={cx(className, { "opacity-60 cursor-not-allowed": disabled })}
    >
      {children}
    </button>
  );
};
Checkout.PrimaryButon = ({ children, className, ...props }: IButtonProps) => {
  return (
    <Checkout.Button
      {...props}
      className={cx(
        "rounded-md bg-blue-600 px-2 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white",
        className
      )}
    >
      {children}
    </Checkout.Button>
  );
};

Checkout.SecondaryButton = ({
  children,
  className,
  ...props
}: IButtonProps) => {
  return (
    <Checkout.Button
      {...props}
      className={cx(
        "rounded-md bg-gray-300 px-2 py-1.5 text-sm font-semibold text-gray-800 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white",
        className
      )}
    >
      {children}
    </Checkout.Button>
  );
};
