import { createBrowserRouter } from "react-router-dom";
import { Home } from "./Home";
import { NotFound } from "./NotFound";
import { Refund } from "./Refund";
import { Layout } from "./Layout";
import { Mission } from "./Mission";

export default createBrowserRouter([
    {
        element: <Layout />,
        children: [
            {
                path: '/',
                index: true,
                element: <Home />,
            },
            {
                path: 'refund',
                element: <Refund />
            },
            {
                path: 'our-mission',
                element: <Mission />
            },
            {
                path: '*',
                element: <NotFound />,
            }
        ],
    },
]);
