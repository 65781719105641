import { createContext, useContext, useMemo, useState } from "react";

import { IRCContainer, IProduct } from "../interfaces";

export interface ICardContext {
    total: number;
    products: IProduct[];
    reset(): void;
    discount: number;
    toPay: number;
    remove(index: number): void;
    add(product: IProduct): void;
}

const CardContext = createContext<ICardContext | null>(null);

function useProvideCard(): ICardContext {
    const [products, setProducts] = useState<IProduct[]>([]);
    const reset = () => setProducts([])
    const add = (product: IProduct) => setProducts(products.concat([product]));
    const remove = (index: number) => setProducts(products.filter((_, pi) => pi !== index));

    const total = useMemo(() => products.reduce((total, product) => total += product.options?.[0]?.price || 0, 0), [products]);
    const discount = useMemo(() => total > 500 ? 10 : 0, [total]);
    const toPay = useMemo(() => {
        const unit = total / 100;
        return parseFloat((total - (discount * unit)).toFixed(2));
    }, [total, discount]);

    return { products, add, remove, total, reset, discount, toPay };
}

export const useCard = () => useContext(CardContext)!;

export function CardProvider(props: IRCContainer) {
    const ctx = useProvideCard();

    return (
        <CardContext.Provider value={ctx}>
            {props.children}
        </CardContext.Provider>
    )
}
