import cx from 'classnames';
import { Fragment, ReactNode, useRef } from "react";
import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { isUndefined } from "lodash";
import { IRCContainer } from "../../interfaces";

export default function Dialog({
  visible = false,
  onClose,
  children,
  onOk,
  title,
  small,
  clean,
  footer = null,
}: IRCContainer & {
  visible: boolean;
  clean?: boolean;
  title?: string;
  onOk?: () => void;
  onClose(): void;
  small?: boolean;
  footer?: ReactNode | null;
}) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={visible} as={Fragment}>
      <HeadlessDialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            style={{ zIndex: 1250 }}
          />
        </Transition.Child>

        <div
          className="fixed inset-0 w-screen overflow-y-auto"
          style={{ zIndex: 1500 }}
        >
          <div className="flex min-h-full items-start justify-center p-4 sm:items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* sm:max-w-lg lg:max-w-1/2 */}
              <HeadlessDialog.Panel className={cx("relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full lg:w-1/2",{
                'sm:w-full sm:max-w-lg': small
              })}>
                <div className={cx("bg-white", { 'px-4 pb-4 pt-5 sm:p-6 sm:pb-4': !clean })}>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 sm:mt-0 sm:text-left w-full">
                      {title && (
                        <HeadlessDialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {title}
                        </HeadlessDialog.Title>
                      )}
                      <div>{children}</div>
                    </div>
                  </div>
                </div>
                {isUndefined(footer) ? (
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => onOk?.() || onClose()}
                    >
                      Ok
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => onClose()}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  footer
                )}
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
}
