export function Companies() {
  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
          Discounted gift cards for popular services and shops
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 opacity-50 hover:opacity-100 cursor-pointer brightness-0"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/603px-Amazon_logo.svg.png?20220213013322"
            alt="Amazon"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 opacity-50 hover:opacity-100 cursor-pointer brightness-0"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Apple_Music_logo.svg/512px-Apple_Music_logo.svg.png?20190908141757"
            alt="Apple music"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 opacity-50 hover:opacity-100 cursor-pointer brightness-0"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Steam_2016_logo_black.svg/1920px-Steam_2016_logo_black.svg.png"
            alt="Steam"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1 opacity-50 hover:opacity-100 cursor-pointer brightness-0"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Xbox_Live_logo.svg/512px-Xbox_Live_logo.svg.png"
            alt="Xbox"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1 opacity-50 hover:opacity-100 cursor-pointer brightness-0"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/PlayStation_logo.svg/200px-PlayStation_logo.svg.png"
            alt="Playstation"
            width={158}
            height={48}
          />
        </div>
      </div>
    </div>
  );
}
