import "./index.css";
import cx from "classnames";
import { useMemo, useState } from "react";

import products from "../../data/products";
import { IProduct } from "../../interfaces";
import { useCard } from "../../context/card.context";
import { ProductCard } from "../../components/product-card";
import { CategorySelector } from "../../components/category-selector";
import Dialog from "../../components/dialog";
import { ProductDrilldown } from "../../components/product-drilldown";

const texts: Record<
  keyof typeof products,
  { title: string; description: string }
> = {
  all: {
    title: "All",
    description:
      "Embark on a journey through our expansive selection of gift cards, where every click unveils a new realm of gifting possibilities! 🎁 Whether it's iTunes for the music aficionado, Amazon for the avid shopper, or a myriad of other options, we've got something for everyone. Delight your friends, family, or even yourself with the freedom to choose their favorite digital treasures. With hassle-free delivery straight to their inbox, spreading joy has never been so convenient. Explore, select, and gift with ease at our one-stop shop for all your digital gifting needs! ✨",
  },
  crypto: {
    title: "Crypto vouchers",
    description:
      "Dive headfirst into the wild world of crypto with our hilarious selection of gift cards for popular exchanges! 🚀 Whether they're a crypto newbie or a seasoned hodler, these cards will have them grinning from ear to ear as they navigate the blockchain. From Bitcoin to Dogecoin (much wow!), give them the gift of financial shenanigans and moon missions. Delivered faster than you can say \"blockchain\", these cards are the ultimate present for the crypto-curious. Start their crypto journey with a laugh and a bang – it's the gift that keeps on giving (and hodling)! 💰🚀",
  },
  gaming: {
    title: "Gaming services",
    description:
      "Gear up for gaming greatness with our power-packed selection of gift cards for top gaming services! 🎮 Whether they're conquering virtual worlds on Steam, leveling up their Xbox game, or diving into the PlayStation universe, we've got the perfect gift for every gamer. From epic adventures to multiplayer mayhem, these cards unlock a treasure trove of entertainment possibilities. Delivered faster than a respawn, these gift cards are your ticket to gaming glory. Give the gift of endless fun and excitement with our gaming service gift cards today! 🎮🔥",
  },
  entertainment: {
    title: "Entertainments",
    description:
      "Lights, laughter, and limitless entertainment await with our array of gift cards for top-notch services like Netflix, iTunes, and beyond! 🎬 Whether they're in the mood for a movie marathon, a binge-worthy TV series, or the latest chart-topping tunes, these cards deliver all the thrills. From comedy specials to gripping dramas, give the gift of unforgettable moments and endless enjoyment. Treat your loved ones to a world of entertainment at their fingertips with our stellar selection of gift cards! 🍿✨",
  },
  streaming: {
    title: "Streaming services",
    description:
      "🎉 Get ready to stream like never before with our fabulous selection of gift cards for all your favorite streaming services! 🍿 Whether you're into thrilling dramas, side-splitting comedies, or heart-pounding action, we've got you covered with gift cards for Netflix, Hulu, Disney+, and more! 🎬 Say goodbye to boring evenings and hello to endless entertainment possibilities with just a few clicks! 💫 With our streaming service gift cards, you're not just giving a gift; you're giving the gift of adventure, laughter, and unforgettable moments. 🎁 So why wait? Grab a gift card today and let the binge-watching begin! 🌟 Because when it comes to entertainment, the only limit is your imagination! 🚀",
  },
  shops: {
    title: "Shops",
    description:
      "🎁 Ready to unlock a universe of shopping delights? Our treasure trove of gift cards opens the door to endless retail adventures! 💼 Whether you're hunting for gadgets on Amazon, fashion gems on eBay, or sleek tech at Apple, we've got the key to your shopping spree! 🛒 Say farewell to gifting guesswork and embrace the joy of choice with our versatile gift cards. 🌟 Because let's be real, who doesn't love the thrill of picking out their own perfect present? 🎉 Don't delay, dive into the world of endless options today! 🌈 After all, the only limit is your shopping cart's capacity! 🎈",
  },
};

export function ProducstList({ className, onOpenProduct }: { className?: string, onOpenProduct(product: IProduct): void }) {
  const card = useCard();
  const [view, setView] = useState<keyof typeof products>("all");

  const text = useMemo(() => texts[view], [view]);
  const list = useMemo(() => products[view], [view]);

  return (
    <div className={cx("ProductsList lg:p-0 p-3", className)}>
      <CategorySelector
        selected={view}
        onSelect={setView}
        categories={
          [
            "all",
            "crypto",
            "entertainment",
            "gaming",
            "streaming",
            "shops",
          ] as Array<keyof typeof products>
        }
      />
      <div>
        <ProducstList.CategoryDescription
          title={text.title}
          description={text.description}
        />
        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">
          {list.map((product: IProduct, index: number) => (
            <ProductCard
              onSelect={() => onOpenProduct(product)}
              onAddToCard={card.add}
              product={product}
              key={Math.random()}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

ProducstList.CategoryDescription = function CategoryDescription({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <div className="overflow-hidden text-justify">
      {/* <h2 className="text-xl mb-2">{title}</h2> */}
      {description && (
        <p className="text-sm mb-4 text-gray-600">{description}</p>
      )}
    </div>
  );
};
