import cx from "classnames";
import { capitalize } from "lodash";
import products from "../../data/products";

export interface ICategorySelectorProps<T> {
  selected: T;
  categories: T[];
  onSelect(item: T): void;
}

export function CategorySelector<T extends keyof typeof products>({
  selected,
  onSelect,
  categories,
}: ICategorySelectorProps<T>) {
  return (
    <nav
      className="mx-auto flex flex-col md:flex-row max-w-7xl items-center justify-between p-6 lg:px-8"
      aria-label="Global"
    >
      {categories.map((category) => (
        <a
          rel="nofollow"
          href={`#${category}`}
          onClick={() => onSelect(category)}
          className={cx("text-sm font-semibold leading-6 text-gray-900")}
        >
          <span className={cx({ underline: selected === category })}>
            {capitalize(String(category))}
          </span>
          <span className="ml-1 text-gray-500">
            ({products[category].length})
          </span>
        </a>
      ))}
    </nav>
  );
}
