import { Asset, Currency } from "./payment-methods";

export const cached: Partial<Record<Asset, Partial<Record<Currency, number>>>> =
  {
    btc: { USD: 64085.2 },
    doge: { USD: 0.14981 },
    ltc: { USD: 85.21 },
    matic: { USD: 0.7092 },
    near: { USD: 7.148 },
    trx: { USD: 0.11812 },
    xmr: { USD: 118.7 },
  };

export function getCachedRate(symbol: Asset, base: Currency): number {
  const rate = cached[symbol]?.[base];

  if (!rate) {
    throw new Error("Invalid pair");
  }

  return rate;
}
