export const config = {
  domain: process.env.REACT_APP_DOMAIN,
  locale: process.env.REACT_APP_LOCALE || "en-US",

  currency: process.env.REACT_APP_CURRENCY || "USD",
  symbol: process.env.REACT_APP_CURRENCY_SYMBOL || "$",

  exchange: {
    token: process.env.REACT_APP_RAPIDAPI_KEY,
    host: process.env.REACT_APP_RAPIDAPI_EXCHANGE_HOST,
  },
};
