import { methods } from "../../data/payment-methods";
import "./index.css";

export function Hero() {
  return (
    <div className="Hero mx-auto max-w-7xl sm:px-6 sm:py-10 lg:py-10 lg:px-8 ">
      <div className="relative isolate overflow-hidden bg-slate-100 dashed-grid-paper px-6 pt-16 shadow-2xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
        <svg
          viewBox="0 0 1024 1024"
          className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
          aria-hidden="true"
        >
          <circle
            cx="512"
            cy="512"
            r="512"
            fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
            fill-opacity="0.7"
          />
          <defs>
            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
              <stop stop-color="#3b82f6" />
              <stop offset="1" stop-color="#0048bd" />
            </radialGradient>
          </defs>
        </svg>
        <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
          <h2 className="text-3xl font-bold tracking-tight text-gray-800 sm:text-4xl">
            Ready for unbeatable savings?
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-500">
            Discover a world of offers, from top service and shop gift cards to
            cutting-edge Crypto payments – revolutionize your transactions!
          </p>
          <div className="mt-5">
            <Hero.CryptoMethods />
          </div>
          <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
            <a
              href="#categories"
              rel="nofollow"
              className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              View all offers
            </a>
          </div>
        </div>
        {/* https://tailwindui.com/img/component-images/dark-project-app-screenshot.png */}
        <div className="relative mt-16 lg:h-80 lg:mt-8">
          <img
            className="absolute left-0 top-0 max-w-none bg-white/5 ring-1 ring-white/10"
            src={require("./assets/hero-illustration.png")}
            alt="App screenshot"
          />
        </div>
      </div>
    </div>
  );
}

const list = [
  methods.btc,
  methods.doge,
  methods.ltc,
  methods.matic,
  methods.monero,
  methods.near,
  methods.trx,
  methods.usdt_bep20,
];
Hero.CryptoMethods = function CryptoMethods() {
  return (
    <div className="flex flex-row items-center justify-center lg:justify-start">
      {list.map((method) => (
        <img className="h-7 mr-3 opacity-65 hover:opacity-100 cursor-pointer" src={method.logo} alt={method.title} />
      ))}
    </div>
  );
};
