import CountUp from 'react-countup'

const stats = [
    { id: 1, name: 'Gift cards sold', value: <><CountUp delay={0.3} end={44} /> million</> },
    { id: 2, name: 'Saved moneys for happy customers', value: <>$<CountUp delay={.5} end={119} /> millions</> },
    { id: 3, name: 'Regular users', value: <CountUp delay={.7} end={46000} /> },
  ]
  
  export default function Stats() {
    return (
      <div id="categories" className="py-10 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
            {stats.map((stat) => (
              <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    )
  }