import "./index.css";

import { useMemo, useState } from "react";
import { motion } from "framer-motion";
import { first, maxBy, minBy, some } from "lodash";

import { formatAmount } from "../../utils";
import { IProduct, IProductOption } from "../../interfaces";

export function ProductCard({
  product,
  onAddToCard,
  onSelect,
}: {
  product: IProduct;
  onSelect(): void,
  onAddToCard(product: IProduct): void;
}) {
  const isTop = useMemo(() => product.options.some(option => option.price >= 200), [product.options]);
  console.log({ product, isTop });
  const [option, setOption] = useState(first(product.options)!);
  const onAdd = () => onAddToCard({ ...product, options: [option] });

  return (
    <motion.div
      onClick={onSelect}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1,  }}
      exit={{ opacity: 0, scale: 0.7 }}
      transition={{ duration: 0.3 }}
      className="group border flex flex-col cursor-pointer"
    >
      <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7 flex-grow">
        <img
          src={product.image || "https://tailwindui.com/img/ecommerce-images/category-page-04-image-card-02.jpg"}
          alt="Tall slender porcelain bottle with natural clay textured body and cork stopper."
          className="h-full w-full object-cover object-center group-hover:opacity-75"
        />
      </div>
      <div className="px-2 pb-3 bg-white overflow-hidden">
        <h3 className="mt-2 text-sm text-gray-700">{isTop ? '🌟 ': ''}{product.name}</h3>
        {product.description && (
          <p className="text-xs text-gray-500 mb-4">{product.description}</p>
        )}
        {/* <p className="mt-1 text-lg font-medium text-gray-900">$48</p> */}
        <ProductCard.OptionsList
          options={product.options}
          onSelect={setOption}
        />
      </div>
    </motion.div>
  );
}

ProductCard.OptionsList = function ProductOptionsList({
  options,
  onSelect,
}: {
  options: IProductOption[];
  onSelect(option: IProductOption): void;
}) {
  const min = useMemo(() => minBy(options, "price")!, [options]);
  const max = useMemo(() => maxBy(options, "price")!, [options]);

  return (
    <div className="flex flex-col">
      <div className="text-xs my-1">
        <span className="mr-1">Available options:</span>
        <strong>
        <ProductCard.Option onSelect={onSelect} option={min} />
        {" ... "}
        <ProductCard.Option onSelect={onSelect} option={max} />
        </strong>
      </div>
      <div className="flex flex-row items-center">
        <div className="flex-grow text-md font-medium text-gray-900">
          From {formatAmount(min.price)}
        </div>
        <div className="text-xs">
          <button className="text-blue-500 hover:underline">View more</button>
        </div>
      </div>
    </div>
  );
};

ProductCard.Option = function ProductOption({
  option,
  onSelect,
}: {
  option: IProductOption;
  onSelect(option: IProductOption): void;
}) {
  return <span onClick={() => onSelect(option)}>{option.name}</span>;
};
