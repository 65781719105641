import { Hero } from "../components/hero";
import Stats from "../components/stats";
import { ProducstList } from "../containers/producst-list";
import { Companies } from "../components/companies";
import About from "../components/about";
import { useLayout } from "./Layout";

export function Home() {
  const layout = useLayout();

  return (
    <div>
      <Hero />
      <Stats />
      <ProducstList onOpenProduct={layout.toggleProduct} />
      <Companies />
      <About />
    </div>
  );
}
