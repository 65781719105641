import { wallets } from "./wallets";

export interface IPaymentMethodConfig {
  title: string;
  logo: string;
  description: string;
  wallets: string[];
  asset: Asset;
}

export enum Currency {
  Usd = 'USD',
  Eur = 'EUR',
  Rub = 'RUB',
  Aed = 'AED',
  Inr = 'INR',
}

export enum Asset {
  Doge = 'doge',
  Btc = 'btc',
  Ltc = 'ltc',
  Matic = 'matic',
  Xmr = 'xmr',
  Near = 'near',
  Trx = 'trx',
  Usdt = 'usdt',
}

export const methods: Record<keyof typeof wallets, IPaymentMethodConfig> = {
  doge: {
    title: "Doge",
    description: "",
    wallets: wallets.doge,
    asset: Asset.Doge,
    logo: 'https://assets.coingecko.com/coins/images/5/standard/dogecoin.png?1696501409'
  },
  btc: {
    title: "Bitcoin",
    description: "",
    wallets: wallets.btc,
    asset: Asset.Btc,
    logo: 'https://assets.coingecko.com/coins/images/1/standard/bitcoin.png?1696501400'
  },
  ltc: {
    title: "Litecoin",
    description: "",
    wallets: wallets.ltc,
    asset: Asset.Ltc,
    logo: 'https://assets.coingecko.com/coins/images/2/standard/litecoin.png?1696501400'
  },
  matic: {
    title: "Polygon Matic",
    description: "",
    wallets: wallets.matic,
    asset: Asset.Matic,
    logo: 'https://assets.coingecko.com/coins/images/4713/standard/polygon.png?1698233745',
  },
  monero: {
    title: "Monero",
    description: "",
    wallets: wallets.monero,
    asset: Asset.Xmr,
    logo: 'https://assets.coingecko.com/coins/images/69/standard/monero_logo.png?1696501460'
  },
  near: {
    title: "Near",
    description: "",
    wallets: wallets.near,
    asset: Asset.Near,
    logo: 'https://assets.coingecko.com/coins/images/10365/standard/near.jpg?1696510367'
  },
  trx: {
    title: "Tron",
    description: "",
    logo: 'https://assets.coingecko.com/coins/images/1094/standard/tron-logo.png?1696502193',
    wallets: wallets.trx,
    asset: Asset.Trx
  },
  usdt_bep20: {
    title: "USDT BEP-20",
    description: "",
    logo: "https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661",
    wallets: wallets.usdt_bep20,
    asset: Asset.Usdt
  },
  usdt_matic: {
    title: "USDT Polygon",
    description: "",
    logo: "https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661",
    wallets: wallets.usdt_matic,
    asset: Asset.Usdt
  },
  usdt_trc20: {
    title: "USDT TRC-20",
    description: "",
    logo: "https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661",
    wallets: wallets.usdt_trc20,
    asset: Asset.Usdt
  },
};
