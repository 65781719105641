import "./index.css";

import ShoppingCardIcon from "@heroicons/react/16/solid/ShoppingCartIcon";

import { useCard } from "../../context/card.context";
import { formatAmount } from "../../utils";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export function Header({ onOpenCard }: { onOpenCard(): void }) {
  useEffect(() => {
    const fn = (event: any) => {
      const header = document.querySelector(".Header");
      if (!header) {
        return;
      }

      console.log(header.scrollTop);
      if (window.scrollY <= header.clientHeight) {
        header.classList.remove("border-b");
        header.classList.remove("shadow-2xl");
      } else {
        header.classList.add("border-b");
        header.classList.add("shadow-2xl");
      }
    };
    window.addEventListener("scroll", fn);

    return () => window.removeEventListener("scroll", fn);
  }, []);

  return (
    <header className="Header py-2">
      <div className="Content">
        <h1 className="Title">
          <Link to="/">
            <img src={require("./assets/Next.png")} className="h-12" />
          </Link>
        </h1>
        <div className="text-sm leading-8">
          <Header.CardButton onClick={onOpenCard} />
        </div>
      </div>
    </header>
  );
}

Header.CardButton = function HeaderCardButton({
  onClick,
}: {
  onClick(): void;
}) {
  const card = useCard();

  return (
    <div className="flex flex-row items-center">
      <div className="flex flex-row items-center cursor-pointer">
        <ShoppingCardIcon className="h-5 mr-2" />{" "}
        {card.products.length ? (
          <button onClick={onClick}>
            {card.products.length} items in cart:{" "}
            <strong className="ml-2">{formatAmount(card.total)}</strong>
          </button>
        ) : (
          <a href="#categories" rel="nofollow" className="hover:underline">
            Your cart is empty
          </a>
        )}
      </div>
      <button className="hidden ml-4 text-xs lg:block bg-green-500 text-gray-100 rounded-md px-2 py-1">
        🎁 Unlock 10% Off on {formatAmount(500)}+
      </button>
    </div>
  );
};
