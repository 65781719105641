export default [
  {
    "image": "https://images.g2a.com/1024x576/1x1x1/netflix-gift-card-200-try-netflix-key-turkey-i10000006307085/5b695a6eae653ac68e67f5e3",
    "name": "Netflix Gift Card",
    "description": "Use it as a replacement for your credit card, to cover your monthly bill. Give it to someone as a gift, and let them dive into great collection of even greater movies and TV shows",
    "options": [
      { "price": 6, "name": "15$" },
      { "price": 9, "name": "20$" },
      { "price": 13, "name": "25$" },
      { "price": 18, "name": "30$" },
      { "price": 22, "name": "50$" },
      { "price": 31, "name": "60$" },
      { "price": 70, "name": "100$" }
    ]
  },
  {
    "image": "https://images.g2a.com/1024x576/1x1x1/spotify-premium-subscription-card-12-months-spotify-key-india-i10000000243132/6282993e386d49b69b1293bc",
    "name": "Spotify Premium",
    "description": "Subscription Card | GLOBAL",
    "options": [
      { "price": 8, "name": "1 month" },
      { "price": 24, "name": "3 months" },
      { "price": 80, "name": "12 months" },
    ]
  },
  {
    "image": "https://images.g2a.com/1024x576/1x1x1/youtube-premium-12-months-youtube-account-global-i10000337507018/4249c4f95a5d4aa1ae87a3f8",
    "name": "Youtube Premium",
    "description": "YouTube Premium is an additional YouTube service adding several features to improve the overall YouTube experience for its users",
    "options": [
      { "price": 1, "name": "3 months" },
      { "price": 3, "name": "6 months" },
      { "price": 6, "name": "12 months" },
    ]
  },
  {
    "image": "https://images.g2a.com/1024x576/1x1x1/apple-tv-trial-3-months-apple-key-united-states-i10000339474001/fcc70c3729a443a7a1060e3f",
    "name": "Apple TV + Trial 3 Months | Worldwide",
    "description": "Apple TV+ is a streaming service from Apple. It features exclusive Apple Original shows and movies from some of the industry's top talent, with new premieres arriving each month.",
    "options": [
      { "price": 5, "name": "3 month" },
    ]
  },
  {
    "image": "https://images.g2a.com/1024x576/1x1x1/amazon-prime-video-1-month-amazon-account-global-i10000326281003/bad83eec540a413c888d8132",
    "name": "Amazon Prime Video | GLOBAL",
    "description": "Join Amazon Prime to watch popular movies and TV shows, including award-winning Amazon Originals.",
    "options": [
      { "price": 3, "name": "1 month" },
      { "price": 12, "name": "12 months" },
    ]
  },
  {
    "image": "https://images.g2a.com/1024x576/1x1x1/hbo-max-by-primepass-3-months-primepass-key-brazil-i10000503624002/c71cb91e000045aa9d66a8af",
    "name": "HBO MAX by Primepass",
    "description": "Explore a world of entertainment with HBO Max, providing instant and limitless access to an array of your favorite HBO series, blockbuster films, captivating documentaries, thrilling championships, and much more",
    "options": [
      { "price": 3, "name": "1 month" },
      { "price": 14, "name": "3 months" },
      { "price": 23, "name": "6 months" },
    ]
  },
  {
    image: "https://dundle.com/cdn-cgi/image/format=auto,width=110,fit=cover,quality=85/https://cdn.dundle.com/resources/images/products/480w/hulu-480w.png",
    "name": "Hulu",
    "description": "With a Hulu Card, you can access one of the top-rated streaming services without hassle. Viewers in America can enjoy thousands of movies, series and groundbreaking originals that everyone is talking about!",
    "options": [
      { "price": 11, "name": "25$" },
      { "price": 27, "name": "50$" },
      { "price": 82, "name": "100$" },
    ]
  }
]
