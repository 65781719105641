export const wallets = {
  doge: [
    'DTxtspPRN4bgUoWAVBGVc66hv5JiLrkxDA',
  ],
  usdt_bep20: [
    '0x374B40521d7abbfD0547627113C3cd19FaAa7Bcb',
  ],
  usdt_trc20: [
    'TAGL4EvC2zzoiBKyE3hfEXqrxPpM38YHt4',
  ],
  trx: [
    'TAGL4EvC2zzoiBKyE3hfEXqrxPpM38YHt4',
  ],
  btc: [
    'bc1qqkv2wky9clguj9kffpe8fruh3u8mclfae730qp',
  ],
  matic: [
    '0x374B40521d7abbfD0547627113C3cd19FaAa7Bcb',
  ],
  usdt_matic: [
    '0x374B40521d7abbfD0547627113C3cd19FaAa7Bcb',
  ],
  monero: [
    '48BUr8jcwrvh2TGMFNzRUP9eJQJHqbaG1QFrxagbyVz66MYNwNnvJQTTmR7hXDuWbbKh5NF4CLsG4NxXRXqapcG5PPeG63C',
  ],
  ltc: [
    'LWwrnc6etQmTosobWDDA1NmJMp4idLt8TU',
  ],
  near: [
    '0x374B40521d7abbfD0547627113C3cd19FaAa7Bcb',
  ],
};
