import axios from 'axios'
import { UseQueryOptions, useQuery } from "react-query";

import { config } from "../utils";
import { getCachedRate } from '../data/rates-cache';
import { Asset, Currency } from '../data/payment-methods';
import { random, range, sample } from 'lodash';

const sleep = (time: number) => new Promise(resolve => setTimeout(resolve, time));
async function getExchangeRateApi(symbol: Asset, base: Currency, shoulSleep: boolean = true) {
  if (shoulSleep) {
    await sleep(sample(range(1500, 3500, 1000))!);
  }

  if (base == Currency.Usd && symbol === Asset.Usdt) {
    return 1;
  }

  const options = {
    method: 'GET',
    url: `https://${config.exchange.host}/exchanges/binance/${symbol}`,
    params: { base },
    headers: {
      'X-RapidAPI-Key': config.exchange.token,
      'X-RapidAPI-Host': config.exchange.host,
    }
  };

  try {
    const response = await axios(options).then(({ data }) => data);
    return parseFloat(response.data.price)
  } catch(err) {
    return getCachedRate(symbol, base);
  }
}

export function useExchangeRatesQuery(
  asset: Asset,
  options: UseQueryOptions<number, Error, number>
) {

  return useQuery<number, Error, number>(
    ["exchange", asset],
    () => getExchangeRateApi(asset, config.currency as Currency),
    options
  );
}
