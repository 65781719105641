export default [
    {
        "image": "https://images.g2a.com/1024x576/1x1x1/amazon-gift-card-50-usd-amazon-united-states-i10000001698084/59e5ae945bafe388fc3cb5f5",
        "name": "Amazon Gift Card | US",
        "description": "Browse through apps, clothes, video games, and endless decorative accessories.",
        "options": [
          { "price": 10, "name": "25$" },
          { "price": 35, "name": "50$" },
          { "price": 50, "name": "100$" },
          { "price": 90, "name": "150$" },
          { "price": 120, "name": "200$" },
          { "price": 150, "name": "300$" },
          { "price": 200, "name": "500$" }
        ]
      },
      {
        "image": "https://images.g2a.com/1024x576/1x1x1/ebay-gift-card-100-usd-united-states-i10000004303007/3be52575ff5d4c87b64a51e4",
        "name": "Ebay Gift Card | USA",
        "description": "Finding the perfect gift for that special someone is a challenge. How would you like the power to give the perfect gift, every time? An eBay gift card now!",
        "options": [
          { "price": 10, "name": "25$" },
          { "price": 35, "name": "50$" },
          { "price": 50, "name": "100$" },
          { "price": 90, "name": "150$" },
          { "price": 120, "name": "200$" },
          { "price": 150, "name": "300$" },
          { "price": 200, "name": "500$" }
        ]
      },
      {
        "image": "https://images.kinguin.net/g/carousel-main/media/images/products/_ASOS_screen.png",
        "name": "ASOS Gift Card | UNITED STATES",
        "description": "Discover more than 850 brands on-site – like Nike, The North Face, Polo Ralph Lauren on ASOS!",
        "options": [
          { "price": 10, "name": "25$" },
          { "price": 35, "name": "50$" },
          { "price": 50, "name": "100$" },
          { "price": 90, "name": "150$" },
          { "price": 120, "name": "200$" },
          { "price": 150, "name": "300$" },
          { "price": 200, "name": "500$" }
        ]
      },
      {
        "image": "https://images.g2a.com/1024x576/1x1x1/victorias-secret-gift-cards-50-usd-key-united-states-i10000338286004/5ecc3bec82b44c5dad7ca627",
        "name": "Victoria's Secret Gift Cards | USA",
        "description": "Victoria's Secret e-gift card is a convenient and flexible way to give the gift of lingerie, sleepwear, beauty products, and accessories to your loved ones.",
        "options": [
          { "price": 10, "name": "25$" },
          { "price": 35, "name": "50$" },
          { "price": 50, "name": "100$" },
          { "price": 90, "name": "150$" },
          { "price": 120, "name": "200$" },
          { "price": 150, "name": "300$" },
          { "price": 200, "name": "500$" }
        ]
      },
      {
        "image": "https://images.g2a.com/1024x576/1x1x1/adidas-store-gift-card-100-usd-adidas-key-united-states-i10000247135021/6064735646177c3c15202872",
        "name": "Adidas Store Gift Card | USA",
        "description": "Adidas is the brand with the Three Stripes, the icon. From the stadium to the streets. From the courts to the catwalk.",
        "options": [
          { "price": 10, "name": "25$" },
          { "price": 35, "name": "50$" },
          { "price": 50, "name": "100$" },
          { "price": 90, "name": "150$" },
          { "price": 120, "name": "200$" },
          { "price": 150, "name": "300$" },
          { "price": 200, "name": "500$" }
        ]
      },
      {
        "image": "https://images.g2a.com/1024x576/1x1x1/nike-store-gift-card-100-eur-nike-key-france-i10000250136012/b557d95f2cce4b59af6b25ac",
        "name": "Nike Store Gift Card | USA",
        "description": "JUST DO IT!Give sport to your loved ones or to motivate yourself to keep moving forward. There is no finish line.",
        "options": [
          { "price": 10, "name": "25$" },
          { "price": 35, "name": "50$" },
          { "price": 50, "name": "100$" },
          { "price": 90, "name": "150$" },
          { "price": 120, "name": "200$" },
          { "price": 150, "name": "300$" },
          { "price": 200, "name": "500$" }
        ]
      },
      {
        "image": "https://images.g2a.com/1024x576/1x1x1/walmart-gift-card-70-usd-walmart-key-united-states-i10000193972034/661961c0e78e4016a4059a74",
        "name": "Walmart Gift Card",
        "description": "JUST DO IT!Give sport to your loved ones or to motivate yourself to keep moving forward. There is no finish line.",
        "options": [
          { "price": 10, "name": "25$" },
          { "price": 40, "name": "50$" },
          { "price": 70, "name": "100$" },
          { "price": 95, "name": "150$" },
          { "price": 130, "name": "200$" },
          { "price": 200, "name": "300$" },
          { "price": 225, "name": "500$" },
        ]
      },
]