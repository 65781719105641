export default [
    {
        "image": "https://images.g2a.com/1024x576/1x1x1/binance-gift-card-88-usdt-key-i10000301475131/dcc2f69c9c7042b48826ba6e",
        "name": "Binance",
        "description": "Binance Gift Card | Any region",
        "options": [
          { "price": 10, "name": "25$" },
          { "price": 35, "name": "50$" },
          { "price": 50, "name": "100$" },
          { "price": 90, "name": "150$" },
          { "price": 120, "name": "200$" },
          { "price": 150, "name": "300$" },
          { "price": 200, "name": "500$" }
        ]
      },
      {
        "image": "https://images.g2a.com/1024x576/1x1x1/whitebit-gift-card-10-usdt-whitebit-key-global-i10000339819001/7910fbe19dc74e4c810e315c",
        "name": "WhiteBit",
        "description": "WhiteBIT Gift Card | WhiteBIT GLOBAL Key",
        "options": [
          { "price": 10, "name": "25$" },
          { "price": 35, "name": "50$" },
          { "price": 50, "name": "100$" },
          { "price": 90, "name": "150$" },
          { "price": 120, "name": "200$" },
          { "price": 150, "name": "300$" },
          { "price": 200, "name": "500$" }
        ]
      },
      {
        "image": "https://images.g2a.com/1024x576/1x1x1/bitcard-bitcoin-giftcard-50-usd-bitcard-key-global-i10000339343005/ca5c6740c0b6425abab950d7",
        "name": "Bitcard",
        "description": "Bitcard Bitcoin Giftcard | Bitcard GLOBAL",
        "options": [
          { "price": 10, "name": "25$" },
          { "price": 35, "name": "50$" },
          { "price": 50, "name": "100$" },
          { "price": 90, "name": "150$" },
          { "price": 120, "name": "200$" },
          { "price": 150, "name": "300$" },
          { "price": 200, "name": "500$" }
        ]
      },
      {
        "image": "https://images.g2a.com/1024x576/1x1x1/azteco-bitcoin-lightning-voucher-25-eur-azteco-key-global-i10000337158003/851ae6ca929b420689239ebf",
        "name": "Azteco",
        "description": "Azteco Bitcoin Lightning Voucher | Azteco GLOBAL Voucher",
        "options": [
          { "price": 10, "name": "25$" },
          { "price": 35, "name": "50$" },
          { "price": 50, "name": "100$" },
          { "price": 90, "name": "150$" },
          { "price": 120, "name": "200$" },
          { "price": 150, "name": "300$" },
          { "price": 200, "name": "500$" }
        ]
      }
]