import { first } from "lodash";
import { useEffect, useMemo } from "react";
import TrashIcon from "@heroicons/react/16/solid/TrashIcon";

import { formatAmount } from "../../utils";
import { IProduct, IRCContainer } from "../../interfaces";
import { useCard } from "../../context/card.context";
import { Link } from "react-router-dom";

export function CardView({ onClose }: { onClose: () => any }) {
  const card = useCard();

  useEffect(() => {
    !card.products.length && onClose?.();
  }, [card.products]);

  return (
    <>
      <div className="pt-3 mb-5">
        {card.products.map((product, index) => (
          <CardView.Product
            onDelete={() => card.remove(index)}
            product={product}
            key={index}
            hasDivider={index < card.products.length - 1}
          />
        ))}
        <CardView.Discounter />
      </div>
      <CardView.SecurityInfo />
    </>
  );
}

CardView.Discounter = function Discounter() {
  const card = useCard();
  return (
    <>
      {card.total < 500 ? (
        <div className="bg-red-600 mt-5 text-xs text-gray-100 text-center rounded px-3 py-2 opacity-60 hover:opacity-100 cursor-pointer">
           ❔ Curious about discounts? Enjoy a 10% off for purchases totaling {formatAmount(500)}! 🤔
        </div>
      ) : (
        <div className="bg-green-600 mt-5 text-xs text-gray-100 text-center rounded px-3 py-2 cursor-pointer">
          🥳 -10% discount applied to your order! 🎉
        </div>
      )}
    </>
  );
};

CardView.SecurityInfo = function () {
  return (
    <>
      <hr className="my-5" />
      <p className="text-xs mb-1 text-gray-500">Learn more about <Link className="text-blue-600 hover:underline" to="refund">Delivery and refund policy</Link></p>
      <div className="flex flex-row items-center">
        <p className="text-xs text-gray-500 flex-grow mr-2">
          Payment processed by verified crypto payment dateway{" "}
          <a className="text-blue-600 hover:underline" rel="nofollow" href="//nowpayments.io">
            NowPayments.io
          </a>
        </p>
        <a rel="nofollow" href="https://www.trustpilot.com/review/nowpayments.io">
          <img
            className="h-3 lg:h-7 mr-2"
            src="https://nowpayments.io/images/trustpilot.svg"
          />
        </a>
        <a rel="nofollow" href="https://www.g2.com/products/nowpayments/reviews">
          <img
            className="h-3 lg:h-7"
            src="https://nowpayments.io/images/G2.svg"
          />
        </a>
      </div>
    </>
  );
};

CardView.Wrapper = function CardViewWrapper({ children }: IRCContainer) {
  const card = useCard();
  if (card.products.length) {
    return <>{children}</>;
  }

  return null;
};

CardView.Product = function CardProductView({
  product,
  onDelete,
  hasDivider,
}: {
  onDelete(): void;
  product: IProduct;
  hasDivider: boolean;
}) {
  const option = useMemo(() => first(product.options)!, [product]);

  return (
    <>
      <div className="flex flex-col lg:flex-row h-full">
        <div className="mb-3 mr-0 lg:mr-3 lg:mb-0">
          <img
            className="col-span-2 object-right md:object-center rounded-md mx-auto lg:mx-0 max-w-64 h-32 object-contain lg:col-span-1 shadow-lg"
            src={product.image}
          />
        </div>
        <div className="flex-grow flex flex-col">
          <div className="h-full">
            <div className="flex flex-row items-center">
              <p className="text-lg font-semibold flex-grow">
                <p>
                  {product.name}{" "}
                  <span className="text-gray-500">{option.name}</span>
                </p>
                <p className="mt-2 text-xs text-gray-500 font-light">
                  {product.description}
                </p>
              </p>
              <button
                onClick={onDelete}
                className="text-red-600 opacity-50 hover:opacity-100 text-sm flex flex-row items-center"
              >
                <TrashIcon className="h-3" /> Remove
              </button>
            </div>
          </div>
          <p className="mt-4 text-right text-lg font-semibold">
            {formatAmount(option.price)}
          </p>
        </div>
      </div>
      {hasDivider && <hr className="my-5" />}
    </>
  );
};

CardView.ModalFooter = function CardViewModalFooter({
  onClose,
  onCheckout,
}: {
  onClose(): void;
  onCheckout(): void;
}) {
  const cart = useCard();
  return (
    <div className="bg-gray-50 px-4 py-3 flex flex-col lg:flex-row sm:px-6 items-center">
      <div className="flex-grow mb-3 lg:mb-0 text-sm font-semibold">
        Total: {formatAmount(cart.total)}
        {cart.discount ? <span className="ml-1"> | Discount: {cart.discount}%</span> : null}
        {cart.discount ? <span className="ml-1"> | To pay: {formatAmount(cart.toPay)}</span> : null}
      </div>
      <div>
        
        <button
          type="button"
          className="mb-3 lg:mb-0 mt-3 mr-1 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => onClose()}
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onCheckout}
          className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
        >
          Checkout now
        </button>
      </div>
    </div>
  );
};
