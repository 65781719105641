import "./index.css";

import { getSupportEmail } from "../../utils/get-support-email";
import { IProduct } from "../../interfaces";
import { useMemo } from "react";
import { range } from "lodash";
import products from "../../data/products";
import { Link } from "react-router-dom";

const favorites = products.all.sort(() => 0.5 - Math.random()).slice(2, 8);

export function Footer({
  onOpenProduct,
}: {
  onOpenProduct(product: IProduct): void;
}) {
  const email = getSupportEmail();

  return (
    <>
      <Footer.Top />
      <footer className="Footer py-10 px-3 bg-gray-900 text-gray-100">
        <div className="mx-auto" style={{ maxWidth: "960px" }}>
          <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            <div className="text-xs">
              <p>Email us:</p>
              <p>
                <a
                  className="underline"
                  rel="nofollow"
                  href={`mailto:${email}`}
                >
                  {email}
                </a>
              </p>
            </div>
            <div>
              <p className="mb-5">All products</p>
              <ul className="text-xs HiglightedProducts">
                {favorites.map((product) => (
                  <li>
                    <button onClick={() => onOpenProduct(product)}>
                      {product.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <p className="mb-5">Info center</p>
              <ul className="text-xs HiglightedProducts">
                <li>
                  <Link
                    to="/our-mission"
                    rel="nofollow"
                    className="mr-3 hover:underline"
                  >
                    Our mission
                  </Link>
                </li>
                <li>
                  <Link
                    to="/refund"
                    rel="nofollow"
                    className="mr-3 hover:underline"
                  >
                    Delivery and Refund policy
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <p className="text-xs mb-2">Secure crypto payments by</p>
              <a
                rel="nofollow"
                href="https://nowpayments.io"
                className="opacity-50 cursor-pointer hover:opacity-100"
              >
                <img src="https://nowpayments.io/images/logo/logo.svg" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

Footer.Top = function FooterTop() {
  return (
    <div className="flex flex-row items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5  w-full">
      <div className="mx-auto" style={{ maxWidth: 960 }}>
        <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
          <p className="text-sm leading-6 text-gray-900">
            Discover unbeatable gift card offers alongside frequent sales!
          </p>
        </div>
      </div>
    </div>
  );
};
