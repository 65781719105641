import cx from "classnames";
import { useCallback, useState } from "react";
import { IProduct, IProductOption } from "../../interfaces";
import { sample } from "lodash";
import { formatAmount } from "../../utils";
import { ICardContext } from "../../context/card.context";

export function ProductDrilldown({
  product,
  onClose,
  onAddToCard,
}: {
  product: IProduct;
  onClose?: () => void;
  onAddToCard: ICardContext["add"];
}) {
  const [option, setOption] = useState<IProductOption>(
    sample(product.options)!
  );

  const onAdd = () => {
    onAddToCard({ ...product, options: [option] });
    onClose?.();
  };

  return (
    <div>
      <h3 className="text-2xl font-bold mb-5">{product.name}</h3>
      <div className="flex flex-col lg:flex-row w-full h-full">
        <div className="mr-0 lg:mr-5 text-center lg:text-left mb-5 lg:mb-0">
          <img
            className="col-span-2 mx-auto lg:mx-0 rounded-md max-w-64 w-full object-contain lg:col-span-1 shadow-lg "
            src={product.image}
          />
        </div>
        <div className="flex-grow flex flex-col">
          <div className="flex-grow">
            <p className="text-sm text-gray-600 mb-5">{product.description}</p>
            <div className="mb-5">
              <p className="mb-2 text-sm text-gray-500">Available options</p>
              <ProductDrilldown.OptionsSelector
                selected={option}
                options={product.options}
                onSelect={setOption}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row">
            <p className="text-xl font-bold mb-5 lg:mb-0 flex-grow">
              {formatAmount(option.price)}
            </p>
            <div>
              <button onClick={onAdd} className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                Add to card
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ProductDrilldown.OptionsSelector = function ProductOptionsSelector({
  options,
  selected,
  onSelect,
}: {
  selected?: IProductOption;
  onSelect(option: IProductOption): void;
  options: IProductOption[];
}) {
  const isActive = useCallback(
    ({ price }: IProductOption) => price == selected?.price,
    [selected]
  );
  return (
    <>
      {options.map((option) => (
        <button
          className={cx(
            "rounded-md mr-2 mb-2 bg-gray-200 px-2 py-1.5 text-xs font-semibold text-gray-800 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white",
            {
              "bg-gray-300": isActive(option),
            }
          )}
          onClick={() => onSelect(option)}
        >
          {option.name}
        </button>
      ))}
    </>
  );
};
