import cx from "classnames";
import { ReactNode } from "react";

export function Input({
  label,
  name,
  className,
  placeholder,
  value,
  type,
  required,
  addonAfter,
  addonBefore,
  autoComplete,
  disabled,
  initialValue,
}: {
  type?: React.HTMLInputTypeAttribute;
  label: string;
  disabled?: boolean;
  addonBefore?: ReactNode;
  addonAfter?: ReactNode;
  name: string;
  required?: boolean;
  initialValue?: string;
  value?: string;
  className?: string;
  placeholder?: string;
  autoComplete?: React.HTMLInputAutoCompleteAttribute;
}) {
  return (
    <div className={cx("sm:col-span-3", className)}>
      <label
        htmlFor={name}
        className="block text-sm leading-6 text-gray-900 font-light"
      >
        {label}
      </label>
      <div className="mt-2 flex flex-row items-center">
        {addonBefore && <span className="mr-2">{addonBefore}</span>}
        <input
          type={type}
          required={required}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          id={name}
          value={value}
          defaultValue={initialValue}
          autoComplete={autoComplete}
          className={cx(
            "block px-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          , { 'bg-gray-200 cursor-not-allowed': disabled })}
        />
        {addonAfter && <span className="ml-2">{addonAfter}</span>}
      </div>
    </div>
  );
}
