export function Mission() {
  return (
    <div className="mx-auto text-sm" style={{ maxWidth: "960px" }}>
      <div className="mb-10">
        <h2 className="text-2xl mb-5 font-semibold">Our Purpose</h2>
        <p>
          Having access to digital content such as music and movies enhances
          people’s lives and experience culture outside of their own. We don’t
          believe the border of your country should define or restrict what
          forms of entertainment you have access to.
        </p>

        <p>
          We started Giffter in 2012 to help make it easier for people
          to have instant access to gift cards, and avoid the risk of buying
          from someone without a name or phone number on websites like eBay.
        </p>
      </div>
      <div className="mb-10">
        <h2 className="text-2xl mb-5 font-semibold">Our Team</h2>
        <p>
          We are a team of people working all over the world, from within the US
          (Colorado, California and Tennessee), Pakistan, Chile and Bangladesh.
          We are motivated by the idea of providing a service to customers all
          over the world that can enrich their lives and experience more
          entertainment easily.
        </p>
      </div>
      <div className="mb-10">
        <h2 className="text-2xl mb-5 font-semibold">
          Dedication to Our Customers
        </h2>
        <p>
          We take customer service and satisfaction very seriously. We are
          constantly working to improve our website, make the browsing easier,
          make the checkout process faster, and of course bring you delivery of
          your gift cards all the more quickly so you can get access to the
          entertainment you want when you want it.
        </p>
      </div>
      <div className="mb-10">
        <h2 className="text-2xl mb-5 font-semibold">
          Dedication to our Environment
        </h2>
        <p>
          We have been dedicated to making our business as environmentally
          responsible as possible. Everyday we try to find ways to reduce the
          amount of paper used in our office and our business transactions, and
          recycle whenever possible. For modes of transportation we choose
          bicycle or walking over driving cars. This alternative mode of
          transportation is better for our planet as well as our collective and
          individual health.
        </p>
      </div>
      <div className="mb-10">
        <h2 className="text-2xl mb-5 font-semibold">Dedicated to Helping</h2>
        <p>
          If there is anything you would like to share with us, please use our
          customer support page. Thanks for your business and support in
          allowing Giffter to provide the power to access US gift cards
          from anywhere in the world.
        </p>
      </div>
    </div>
  );
}
